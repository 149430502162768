import { Container } from "@mui/material";
import { Text } from "@mantine/core";

import PeterImage from "../../../Assets/IMG/Farmers/Articles/segun.jpeg";

import "./styles.scss";
export default function FarmersArticles() {
  const articles = [
    {
      image: PeterImage,
      title: "Segun's success in Ilorin",
      subtitle: "Cutting Feed Costs with Precision",
      description:
        "Segun, a dedicated fish farmer in Ilorin, was determined to grow his business but faced a significant challenge — the high cost of feed. He struggled with balancing the right feeding amounts, often wasting feed or underfeeding his fish, which slowed their growth. With the support of Future Fish AgroTech’s automated feeding system, Segun now controls the feeding process with pinpoint accuracy. The system delivers the right quantity at the perfect time, reducing waste by 25%. Empowered by this technology, Segun not only slashed his costs but also boosted his fish yield by 30%. He now runs his farm more efficiently, giving him the confidence to expand.",
    },
  ];
  return (
    <div className="articles-container">
      <Container>
        <Text className="section-header">Testimonials</Text>
        <div className="articles flex-col items-center">
          {articles.map((article, index) => {
            const directions = ["down", "right", "up", "left"];
            const randomDirection =
              directions[Math.floor(Math.random() + directions.length)];
            return (
              <div
                key={article.title.concat(index.toString())}
                className={`article flex-row justify-between width-100 items-center ${
                  index === 1 ? "article-reversed" : ""
                }`}
                data-aos={`fade-${randomDirection}`}
              >
                <img
                  src={article.image}
                  alt=""
                  className="image"
                  data-aos={`fade-${randomDirection}`}
                />
                <div
                  className="content flex-col"
                  data-aos={`fade-${randomDirection}`}
                >
                  <Text className="title">{article.title}</Text>
                  <Text className="dimmed">{article.subtitle}</Text>
                  <Text className="description">{article.description}</Text>
                </div>
              </div>
            );
          })}
        </div>
      </Container>
    </div>
  );
}
