import { Container, Grid2, Grid2Props } from "@mui/material";

import { Text, Button, NavLink } from "@mantine/core";

import { IconExternalLink } from "@tabler/icons-react";

import FarmAutomationIcon from "../../../Assets/IMG/Home/Services/farmer.png";
import PondMonitoringIcon from "../../../Assets/IMG/Home/Services/pond.png";
import SecuritySystemIcon from "../../../Assets/IMG/Home/Services/secure.png";
import ConsultationIcon from "../../../Assets/IMG/Home/Services/talk.png";
import "./styles.scss";

export default function HomeServices() {
  const services = [
    {
      image: FarmAutomationIcon,
      title: "Farm Automation",
      description:
        "The future of aquaculture is farms that operate with minimal manual intervention, where feeding schedules, water regulation, and environmental controls are all handled automatically.",
    },
    {
      image: PondMonitoringIcon,
      title: "Pond Monitoring",
      description:
        "Pond Monitoring is your gateway to real-time data on all critical parameters affecting your fish's health and growth.",
    },
    {
      image: ConsultationIcon,
      title: "Consultation",
      description:
        "Our Consultation service provides you with direct access to industry experts who will guide you through every step of your aquaculture journey.",
    },
    {
      image: SecuritySystemIcon,
      title: "Security System",
      description:
        "Your fish farm is a valuable asset—don’t leave it unprotected. Our Security System is designed to keep your operation safe from theft, vandalism, and unauthorized access.",
    },
  ];
  const gridItemProps: Grid2Props = {};

  return (
    <div className="services-container" data-aos="fade-up">
      <Container maxWidth="xl" className="pt-10">
        <Text className="section-header">Our Services</Text>
        <br />
        <br />
        <Grid2
          container
          rowGap={4}
          spacing={1}
          direction="row"
          justifyContent="space-evenly"
          wrap="wrap" // Ensures row wrapping on smaller screens
        >
          {services.map((service, index) => (
            <Grid2 key={service.title} className="service-card">
              <div className="service">
                <Text className="number">0{index + 1}</Text>
                <img
                  src={service.image}
                  alt={service.title}
                  className="image"
                />
                <Text className="title">{service.title}</Text>
                <Text className="description">{service.description}</Text>
                <NavLink
                  href="#learn-more"
                  label="Read more"
                  className="read-more-link"
                  rightSection={<IconExternalLink size={17} />}
                />
              </div>
            </Grid2>
          ))}
        </Grid2>
      </Container>
    </div>
  );
}
