import { Avatar, AvatarFallback, AvatarImage } from "@radix-ui/react-avatar";
import { Radar } from "lucide-react";
import nitda from "../../Assets/nitda.png";
import nadf from "../../Assets/nadf.png";

interface SponsorProps {
  icon: JSX.Element;
  name: string;
  link: string;
  img: string;
}

const sponsors: SponsorProps[] = [
  {
    icon: <Radar size={34} />,
    name: "NITDA",
    link: "",
    img: nitda,
  },
  {
    icon: <Radar size={34} />,
    name: "NADF",
    link: "",
    img: nadf,
  },
];

export const Sponsors = () => {
  return (
    <section
      id="sponsors"
      className="w-full py-12 sm:py-8 grid place-self-center"
    >
      <h2
        className="text-center section-header mb-8 text-primary"
        data-aos="fade-up"
      >
        Powered By
      </h2>

      <div
        className="flex flex-wrap justify-center items-center gap-4 md:gap-8"
        data-aos="fade-up"
        data-aos-delay="200"
      >
        {sponsors.map(({ icon, name, link, img }: SponsorProps, index) => (
          <div
            key={name}
            className="flex items-center gap-1 text-muted-foreground/60"
            data-aos="zoom-in"
            data-aos-delay={`${index * 100 + 300}`} // staggered delay for each sponsor
          >
            <Avatar>
              <AvatarImage alt={name} src={img} className="w-40" />
              <AvatarFallback>{icon}</AvatarFallback>
            </Avatar>
            {/* <h3 className="text-xl font-bold">{name}</h3> */}
          </div>
        ))}
      </div>
    </section>
  );
};
