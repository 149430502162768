import { Container } from "@mui/material";
import { Text } from "@mantine/core";

import { motion } from "framer-motion";

import HeroImage from "../../../Assets/IMG/Farmers/Hero.png";
import LogoSmall from "../../../Assets/IMG/SmallLogo.png";
import LogoFull from "../../../Assets/IMG/LogoFull.svg";

import AvatarOne from "../../../Assets/IMG/Farmers/One.png";
import AvatarTwo from "../../../Assets/IMG/Farmers/Two.png";
import AvatarThree from "../../../Assets/IMG/Farmers/Three.png";
import AvatarFour from "../../../Assets/IMG/Farmers/Four.png";
import AvatarFive from "../../../Assets/IMG/Farmers/Five.png";
import OsunImage from "../../../Assets/IMG/Farmers/OsunImage.png";
import AbdulImage from "../../../Assets/IMG/Farmers/AbdulImage.png";
import OkonImage from "../../../Assets/IMG/Farmers/OkonImage.png";

import "./styles.scss";
import { useEffect, useState } from "react";
import { getRandomItem } from "../../../Lib/Methods";
export default function HomeClients2() {
  const comments = [
    "Technological development plays an important role in increased food productivity and improvement in farmer's livelihood. The innovation that Future Fish came up with is much needed in the Aquaculture industry.",
    "Future Fish Farms is redefining aquacultural practices for optimal fish health and growth.",
    "I have always been an advocate for the use of technology in Aquaculture. Future Fish is an ingenious solution that will take Fish production in Nigeria and Africa to the next level.",
  ];

  const avatars = [AvatarOne, AvatarTwo, AvatarThree, AvatarFour, AvatarFive];
  const farmers = [OsunImage, AbdulImage, OkonImage];

  const names = [
    "Osun Idowu Oluwabukunmi",
    "AbdulWaheed Junaid",
    "Okon Eteobong",
  ];
  const details = [
    {
      tag: "Business Development Expert on the Technologies for African Agricultural Transformation",
    },
    {
      tag: "Regional Technical Manager at Olam Group",
    },
    {
      tag: "Secretary, World Aquaculture Society (Africa Chapter)",
    },
  ];

  const [currentComment, setCurrentComment] = useState<number>(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentComment((prevIndex) => (prevIndex + 1) % comments.length);
    }, 4000);

    return () => clearInterval(interval);
  }, []);
  useEffect(() => {
    console.log(currentComment, comments[currentComment]);
  }, [currentComment]);
  const directions = [
    "fade-up",
    "fade-down",
    "fade-left",
    "fade-right",
    "zoom-in",
  ];
  return (
    <div className="comments-container">
      <Container maxWidth="lg">
        <Text className="story-header">Stories</Text>
        <div
          className="story-card flex-col"
          data-aos={getRandomItem(directions)}
        >
          <div className="logo-row flex-row align-center">
            <img src={LogoSmall} alt="" className="small-logo" />
          </div>
          <div
            className="content flex-row justify-between"
            data-aos={getRandomItem(directions)}
          >
            <div className="left flex-col">
              {comments.map((comment, index) => {
                return (
                  <motion.div
                    animate={{
                      opacity: index === currentComment ? 1 : 0,
                    }}
                    className="message-container"
                  >
                    <Text className="message">"{comment}"</Text>
                  </motion.div>
                );
              })}
              <Text className="name">{names[currentComment]}</Text>
              <Text className="organization">
                {details[currentComment].tag}
              </Text>
            </div>
            <div
              data-aos={getRandomItem(directions)}
              className="right flex-col"
            >
              <img src={farmers[currentComment]} alt="" className="image" />
            </div>
          </div>
          <div
            data-aos={getRandomItem(directions)}
            className="flex-row justify-end align-end"
          >
            <img src={LogoFull} alt="" className="full-logo" />
          </div>
        </div>
        <div
          data-aos={getRandomItem(directions)}
          className="flex-row indicators-container align-center justify-center"
        >
          <div className="indicators flex-row align-center">
            {comments.map((comment, index) => {
              return (
                <span
                  key={comment.concat(index.toString())}
                  className={` max-w-[22px] max-h-[22px] h-[22px] w-[22px] indicator indicator-${
                    currentComment === index ? "active" : "inactive"
                  }`}
                ></span>
              );
            })}
          </div>
        </div>
      </Container>
    </div>
  );
}
