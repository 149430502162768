import { Container, Grid2, Grid2Props } from "@mui/material";
import { Text, Card } from "@mantine/core";
import CountUp from "react-countup";
import FarmersTrainedIcon from "../../../Assets/IMG/Home/Stats/farmer.png";
import ActivePondsIcon from "../../../Assets/IMG/Home/Stats/pond.png";
import FeedSavedIcon from "../../../Assets/IMG/Home/Stats/feed.png";
import MoneySavedIcon from "../../../Assets/IMG/Home/Stats/naira.png";
import "./styles.scss";
import { useInViewport } from "@mantine/hooks";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../App";

export default function HomeStats() {
  const authContext = useContext(AppContext);
  const { ref, inViewport } = useInViewport();

  const icons = [
    ActivePondsIcon,
    FarmersTrainedIcon,
    FeedSavedIcon,
    MoneySavedIcon,
  ];

  const gridItemProps: Grid2Props = {
    size: {
      xs: 12,
      sm: 6,
      md: 3,
      lg: 3,
      xl: 3,
    },
  };
  const [isLoadedBefore, setLoadedBefore] = useState(false);

  useEffect(() => {
    if (inViewport) setLoadedBefore(true);
  }, [inViewport]);

  const isShowingStats = inViewport || isLoadedBefore;

  return (
    <div className="stats-container" data-aos="fade-up" ref={ref}>
      <Container maxWidth="lg">
        <Text className="section-header">Stats</Text>
        <br />
        {isShowingStats && (
          <Grid2 container spacing={3} justifyContent="center">
            {authContext?.stats?.map((stat, index) => (
              <Grid2 key={stat.id} {...gridItemProps}>
                <Card shadow="sm" className="stat">
                  <div className="stat-top">
                    <Text className="stat-value">
                      {(stat as any).numeric ? (
                        <>
                          <CountUp duration={2.5} start={0} end={stat.value} />
                          {stat.title.toLowerCase() === "feed saved" && " KG"}
                        </>
                      ) : (
                        stat.value
                      )}
                    </Text>
                    <img
                      src={icons[index]}
                      alt={stat.title}
                      className="stat-icon"
                    />
                  </div>
                  <Text className="stat-label">{stat.title}</Text>
                </Card>
              </Grid2>
            ))}
          </Grid2>
        )}
      </Container>
    </div>
  );
}
