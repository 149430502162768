import { Link } from "react-router-dom";
import { Container, Grid } from "@mui/material";
import { Text } from "@mantine/core";
import Logo from "../../Assets/IMG/LogoFull.svg";
import "./styles.scss";
import {
  IconBrandFacebook,
  IconBrandInstagram,
  IconBrandLinkedin,
  IconBrandX,
  IconBrandYoutubeFilled,
} from "@tabler/icons-react";

export default function Footer() {
  const footerItems = [
    {
      header: "About",
      items: [
        { link: "/about#team-container", label: "Overview" },
        { link: "/about#values-container", label: "Values" },
        { link: "/about#team-container", label: "Team" },
      ],
    },
    {
      header: "Community",
      items: [
        { link: "/farmers", label: "Farmers" },
        {
          link: "https://x.com/FutureFishAgro",
          label: "Follow on X",
          newTab: true,
        },
        {
          link: "https://instagram.com/futurefishagro",
          label: "Instagram",
          newTab: true,
        },
        { link: "/news", label: "Blog" },
        {
          link: "https://www.youtube.com/channel/UCV-4gU0UHWozSuU5LD_mChw",
          label: "YouTube Channel",
          newTab: true,
        },
      ],
    },
    {
      header: "Services",
      items: [
        { link: "/service/farm-automation", label: "Farm Automation" },
        { link: "/service/pond-monitoring", label: "Pond Monitoring" },
        { link: "/service/consultation", label: "Consultation" },
        { link: "/service/security-system", label: "Security System" },
      ],
    },
  ];

  const socialLinks = [
    {
      href: "https://x.com/FutureFishAgro",
      icon: <IconBrandX size={20} />,
      label: "Twitter",
    },
    {
      href: "https://www.facebook.com/profile.php?id=61564849314886&mibextid=LQQJ4d",
      icon: <IconBrandFacebook size={20} />,
      label: "Facebook",
    },
    {
      href: "https://www.linkedin.com/company/future-fish-agrotech/",
      icon: <IconBrandLinkedin size={20} />,
      label: "LinkedIn",
    },
    {
      href: "https://www.youtube.com/channel/UCV-4gU0UHWozSuU5LD_mChw",
      icon: <IconBrandYoutubeFilled size={20} />,
      label: "YouTube",
    },
    {
      href: "https://instagram.com/futurefishagro",
      icon: <IconBrandInstagram size={20} />,
      label: "Instagram",
    },
  ];

  return (
    <div id="contact" className="footer-container" data-aos="fade-up">
      <Container maxWidth="lg">
        <footer className="footer">
          <div className="footer-left">
            <Link to="/" onClick={() => window.scrollTo({ top: 0, left: 0 })}>
              <img src={Logo} alt="FutureFish Logo" className="footer-logo" />
            </Link>
            <Text className="footer-description">
              Empowering African Fish Farmers with Smart and Efficient Solutions
              for a Sustainable Future.
            </Text>
          </div>
          <Grid container spacing={3} className="footer-links">
            {footerItems.map((section, index) => (
              <Grid item xs={12} sm={4} key={index}>
                <Text className="footer-header">{section.header}</Text>
                {section.items.map((item, i) => (
                  <Link
                    to={item.link}
                    target={item.newTab ? "_blank" : undefined}
                    className="footer-link hover:text-[var(--blue-primary)]"
                    key={i}
                  >
                    <Text>{item.label}</Text>
                  </Link>
                ))}
              </Grid>
            ))}
          </Grid>
        </footer>
        <div className="footer-bottom">
          <Text className="footer-copy">
            &copy; {new Date().getFullYear()} FutureFish. All rights reserved.
          </Text>
          <div className="footer-socials">
            {socialLinks.map((social, i) => (
              <a
                href={social.href}
                target="_blank"
                rel="noopener noreferrer"
                className="social-link"
                key={i}
                aria-label={social.label}
              >
                {social.icon}
              </a>
            ))}
          </div>
        </div>
      </Container>
    </div>
  );
}
