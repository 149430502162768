import { Container } from "@mui/material";
import { Group, Text, Accordion } from "@mantine/core";
import "./styles.scss";
import { useContext } from "react";
import { AppContext } from "../../../App";

interface AccordionLabelProps {
  label: string;
}

function AccordionLabel({ label }: AccordionLabelProps) {
  return (
    <Group wrap="nowrap">
      <div>
        <Text fw={500} c="var(--darkest-green)">
          {label}
        </Text>
      </div>
    </Group>
  );
}

export default function HomeFaq() {
  const appContext = useContext(AppContext);

  const items = appContext?.questions?.map((item) => (
    <Accordion.Item value={item.id} key={item.id}>
      <Accordion.Control>
        <AccordionLabel {...item} label={item.title} />
      </Accordion.Control>
      <Accordion.Panel>
        <Text size="sm" c="dimmed" fw={400}>
          {item.value}
        </Text>
      </Accordion.Panel>
    </Accordion.Item>
  ));

  return (
    <div className="faq-container" data-aos="fade-down">
      <Container maxWidth="md">
        <Text className="section-header text-center" fw={600}>
          FAQs
        </Text>
        <br />
        <br />
        <Accordion
          chevronPosition="right"
          variant="contained"
          className="faq-accordion"
        >
          {items}
        </Accordion>
      </Container>
    </div>
  );
}
