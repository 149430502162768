import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

import ArrowRightIcon from "../../../Assets/IMG/ArrowRight.svg";

import CarouselImage1 from "../../../Assets/IMG/Home/JumboCarousel/5.png";
import CarouselImage2 from "../../../Assets/IMG/Home/JumboCarousel/6.png";
import CarouselImage3 from "../../../Assets/IMG/Home/JumboCarousel/7.png";

import "./styles.scss";
import Carousel from "./carousel";

export default function HomeJumbotron() {
  const images = [CarouselImage1, CarouselImage2, CarouselImage3];
  // const [currentImage, setCurrentImage] = useState(0);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setCurrentImage((prevIndex) => (prevIndex + 1) % images.length);
  //   }, 5000);
  //   return () => clearInterval(interval);
  // }, [images.length]);

  return (
    <div className="jumbotron-container">
      {/* Background */}
      <div className="jumbo-bg"></div>

      {/* Main Content */}
      <div className="content-container">
        {/* Left Section */}
        <div className="text-content">
          <h1 className="header">
            The <br />
            Future <br />
            Aquaculture
          </h1>
          <p className="subtitle">
            Empowering African Fish Farmers with Smart and Efficient Solutions
            for a Sustainable Future
          </p>
          <div className="buttons max-lg:justify-evenly max-md:gap-0">
            <Link to="/about" className="btn-primary">
              About Us
            </Link>
            <Link to="/farmers" className="btn-secondary">
              Reviews
              <img className="arrow-icon" src={ArrowRightIcon} alt="arrow" />
            </Link>
          </div>
        </div>

        {/* Right Section */}
        <Carousel images={images} />
      </div>
    </div>
  );
}
