import { useState, useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import Logo from "../../Assets/IMG/LogoFull.svg";

export default function Navbar() {
  const location = useLocation();
  const [isWhite, setIsWhite] = useState(true);
  const [activeLink, setActiveLink] = useState("");
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  const NavLinks = [
    { link: "", label: "Home" },
    { link: "about", label: "About" },
    { link: "transform-my-pond", label: "Transform My Pond" },
    { link: "farmers", label: "Farmers" },
    { link: "news", label: "News" },
    { link: "#", label: "Contact", scroll: true },
  ];

  const services = [
    "farm-automation",
    "pond-monitoring",
    "security-system",
    "consultation",
  ];

  useEffect(() => {
    const { pathname } = location;
    setActiveLink(pathname.replaceAll("/", "").toLowerCase());
  }, [location]);

  useEffect(() => {
    const handleScroll = () => setIsWhite(window.scrollY > 10);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      {/* Desktop Navbar */}
      <motion.div
        initial={false}
        animate={{ backgroundColor: isWhite ? "#ffffff" : "transparent" }}
        className="fixed top-0 left-0 w-full z-50 px-4 py-2 transition-colors duration-300"
      >
        <div className="container mx-auto flex items-center justify-between">
          <Link to="/">
            <img src={Logo} alt="Logo" className="h-10" />
          </Link>
          <div className="hidden md:flex items-center space-x-6">
            {NavLinks.map((link) => (
              <div key={link.label} className="relative">
                {link.link === "transform-my-pond" ? (
                  <div className="group">
                    <Link
                      to={`/${link.link}`}
                      className={`${
                        activeLink === link.link
                          ? "text-green-600"
                          : "text-gray-800"
                      } hover:text-green-600 font-medium text-lg transition-all`}
                    >
                      {link.label}
                    </Link>
                    <div className="absolute left-0 hidden group-hover:block bg-white shadow-lg mt-0.5 w-48">
                      {services.map((service) => (
                        <Link
                          key={service}
                          to={`/service/${service}`}
                          className="block px-4 py-2 text-gray-700 hover:bg-gray-100 capitalize"
                        >
                          {service.replaceAll("-", " ")}
                        </Link>
                      ))}
                    </div>
                  </div>
                ) : link.scroll ? (
                  <a
                    href="#contact"
                    className="text-gray-800 hover:text-green-600 font-medium text-lg transition-all"
                  >
                    {link.label}
                  </a>
                ) : (
                  <Link
                    to={`/${link.link}`}
                    className={`${
                      activeLink === link.link
                        ? "text-green-600"
                        : "text-gray-800"
                    } hover:text-green-600 font-medium text-lg transition-all`}
                  >
                    {link.label}
                  </Link>
                )}
              </div>
            ))}
          </div>
          <button
            className="md:hidden focus:outline-none"
            onClick={() => setIsMenuOpened(!isMenuOpened)}
          >
            <span className="block w-6 h-0.5 bg-gray-800 mb-1"></span>
            <span className="block w-6 h-0.5 bg-gray-800 mb-1"></span>
            <span className="block w-6 h-0.5 bg-gray-800"></span>
          </button>
        </div>
      </motion.div>

      {/* Mobile Navbar */}
      {isMenuOpened && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 bg-white z-50 p-4"
        >
          <div className="flex justify-between items-center">
            <img src={Logo} alt="Logo" className="h-10" />
            <button
              className="focus:outline-none"
              onClick={() => setIsMenuOpened(false)}
            >
              <span className="block w-6 h-0.5 bg-gray-800 mb-1"></span>
              <span className="block w-6 h-0.5 bg-gray-800 mb-1"></span>
              <span className="block w-6 h-0.5 bg-gray-800"></span>
            </button>
          </div>
          <nav className="mt-4 space-y-4">
            {NavLinks.map((link) => (
              <div key={link.label}>
                {link.link === "transform-my-pond" ? (
                  <div>
                    <Link
                      to={`/${link.link}`}
                      className="block text-gray-800 hover:text-green-600 font-medium text-lg"
                      onClick={() => setIsMenuOpened(false)}
                    >
                      {link.label}
                    </Link>
                    <div className="pl-4 mt-2">
                      {services.map((service) => (
                        <Link
                          key={service}
                          to={`/service/${service}`}
                          className="block text-gray-700 hover:text-green-600 text-sm capitalize"
                          onClick={() => setIsMenuOpened(false)}
                        >
                          {service.replaceAll("-", " ")}
                        </Link>
                      ))}
                    </div>
                  </div>
                ) : link.scroll ? (
                  <a
                    href="#contact"
                    className="block text-gray-800 hover:text-green-600 font-medium text-lg"
                    onClick={() => setIsMenuOpened(false)}
                  >
                    {link.label}
                  </a>
                ) : (
                  <Link
                    to={`/${link.link}`}
                    className="block text-gray-800 hover:text-green-600 font-medium text-lg"
                    onClick={() => setIsMenuOpened(false)}
                  >
                    {link.label}
                  </Link>
                )}
              </div>
            ))}
          </nav>
        </motion.div>
      )}
    </>
  );
}
