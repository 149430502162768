import { Link } from "react-router-dom";

import { Container, Grid2, Grid2Props } from "@mui/material";

import {
  Text,
  Button,
  NavLink,
  Card,
  useMantineTheme,
  rem,
} from "@mantine/core";

import { IconExternalLink } from "@tabler/icons-react";

import { Carousel } from "@mantine/carousel";

import BlogImageOne from "../../../Assets/IMG/Home/Blog/One.png";
import BlogImageTwo from "../../../Assets/IMG/Home/Blog/Two.png";
import BlogImageThree from "../../../Assets/IMG/Home/Blog/Three.png";
import BlogImageFour from "../../../Assets/IMG/Home/Blog/Four.png";
import "./styles.scss";
import { useMediaQuery } from "@mantine/hooks";
import { useContext } from "react";
import { AppContext } from "../../../App";
import { getEllipsisWithString } from "../../../Lib/Methods";

export default function HomeBlog() {
  const context = useContext(AppContext);
  const theme = useMantineTheme();
  const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
  const posts = context?.featuredPost;
  return (
    <>
      <div className="blog-container">
        <Container maxWidth="lg">
          <div className="header-row flex-row align-center justify-center">
            <Text className="section-header header">Blog</Text>
            <Link to="/news">
              <Button
                variant="outline"
                className="btn"
                color="var(--blue-primary)"
              >
                View All
              </Button>
            </Link>
          </div>
          <br />
          <br />
          <Carousel
            // slideSize={{ md: "30%", sm: "50%", xs: "25%",  }}
            slideSize="33%"
            slideGap="xl"
            align="start"
            loop
            withIndicators
            draggable
            classNames={{
              root: "posts",
              indicator: "indicator",
              indicators: "indicators",
            }}
            slidesToScroll={mobile ? 1 : 2}
          >
            {(posts as any)?.map((post: any, index: number) => {
              return (
                <Carousel.Slide key={`${post.id} ${index}`}>
                  <div
                    className="post flex-col justify-between"
                    style={{
                      backgroundImage: `url(${post.thumbnail})`,
                    }}
                  >
                    <div className="top flex-col">
                      <Text className="tag" tt="uppercase">
                        {post.tag}
                      </Text>
                      <Text className="title">
                        {getEllipsisWithString(post.title, 40)}
                      </Text>
                    </div>
                    <Link to={`/post/${post.id}`}>
                      <Button
                        className="btn"
                        color="var(--white)"
                        c="var(--darkest-green)"
                        w={120}
                      >
                        Read Post
                      </Button>
                    </Link>
                  </div>
                </Carousel.Slide>
              );
            })}
          </Carousel>
        </Container>
      </div>
    </>
  );
}
