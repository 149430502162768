import { useNavigate, Link } from "react-router-dom";

import {
  Container,
  Grid2 as Grid,
  Grid2Props as GridProps,
} from "@mui/material";

import {
  Text,
  Button,
  NavLink,
  Card,
  useMantineTheme,
  rem,
  ActionIcon,
  Pagination,
  Popover,
  Divider,
} from "@mantine/core";

import {
  IconEdit,
  IconExternalLink,
  IconPencilPlus,
  IconReload,
  IconTrash,
  IconUserPlus,
} from "@tabler/icons-react";

import "./styles.scss";
import { useMediaQuery } from "@mantine/hooks";
import { useContext, useState } from "react";
import { PerformRequest, usePerformRequest } from "../../Lib/usePerformRequest";
import { Endpoints } from "../../Lib/Endpoints";
import { NonPaginatedResponse, PaginatedResponse } from "../../Lib/Responses";
import { BlogPost } from "../../Lib/Types";
import Loader from "../../Components/Loader";
import { AppContext } from "../../App";
import { getEllipsisWithString } from "../../Lib/Methods";

export default function Blog() {
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const context = useContext(AppContext);
  const [pagination, setPagination] = useState<{ page: number; limit: number }>(
    {
      page: 1,
      limit: 20,
    }
  );
  const {
    data: posts,
    isLoading: isLoadingPosts,
    reloadData: reloadPosts,
  } = usePerformRequest<PaginatedResponse<BlogPost[]>>({
    method: "POST",
    url: Endpoints.GetBlogPosts,
    body: pagination,
  });

  const gridItemProps: GridProps = {
    size: {
      xl: 3,
      lg: 3,
      md: 6,
      sm: 12,
      xs: 12,
    },
  };
  const featuredPost = (context as any)?.featuredPost;
  const lastFeaturedPost = featuredPost?.[featuredPost.length - 1];
  return (
    <div className="blogs-container">
      {context && (
        <Container maxWidth="xl">
          <div className="top flex-row justify-between width-100">
            <Link
              to={`/post/${lastFeaturedPost?.id || ""}`}
              className="featured-post flex-col justify-end"
              style={{
                backgroundImage: `url(${lastFeaturedPost?.thumbnail || ""})`,
              }}
            >
              <div className="content flex-col">
                <span className="tag">{lastFeaturedPost?.tag || "N/A"}</span>
                <Text className="title">
                  {lastFeaturedPost?.title || "No Title"}
                </Text>
              </div>
            </Link>
            <div className="flex-col recent-stories">
              <Card withBorder>
                <div className="stories flex-col width-100 justify-between">
                  {Array.isArray(context?.recentPosts) &&
                    context?.recentPosts.map((post) => (
                      <Link
                        to={`/post/${post.id}`}
                        key={post.id}
                        className="story flex-row justify-between"
                      >
                        <div className="left flex-col">
                          <Text className="title">
                            {getEllipsisWithString(post.title, 50)}
                          </Text>
                          <span className="tag">{post.tag}</span>
                        </div>
                        <img
                          src={post.thumbnail}
                          alt=""
                          className="thumbnail"
                        />
                      </Link>
                    ))}
                </div>
              </Card>
            </div>
          </div>
          <Container maxWidth="lg">
            {isLoadingPosts ? (
              <Loader />
            ) : posts?.data?.length ? (
              <Grid
                container
                className="posts"
                alignItems="center"
                justifyContent="center"
              >
                {posts.data
                  .filter(({ published }) => published)
                  .map(({ id, thumbnail, tag, title }, index) => (
                    <Grid
                      key={id}
                      className="post flex-col justify-between"
                      style={{ backgroundImage: `url(${thumbnail})` }}
                      {...gridItemProps}
                      onClick={() => navigate(`/post/${id}`)}
                    >
                      <div className="top flex-col">
                        <Text className="tag" tt="uppercase">
                          {tag}
                        </Text>
                        <Text className="title">
                          {getEllipsisWithString(title, 50)}
                        </Text>
                      </div>
                    </Grid>
                  ))}
              </Grid>
            ) : (
              <Text>No posts available.</Text>
            )}

            <div className="flex-row width-100 justify-center align-center">
              <Pagination
                mt={10}
                disabled={isLoadingPosts}
                value={pagination.page}
                onChange={(e) => setPagination({ ...pagination, page: e })}
                total={Math.ceil((posts?.totalRecords || 0) / pagination.limit)}
              />
            </div>
          </Container>
        </Container>
      )}
    </div>
  );
}
